.sidebar-item {
    @apply flex rounded px-4 py-2 mb-2 block hover:bg-neutral-800 text-sm;
}

.sidebar-item::before {
    content: "";
    @apply h-4 w-1 my-auto bg-orange-700 -ml-3 mr-1 rounded opacity-0;
}

.sidebar-item.active::before {
    @apply opacity-100;
}

.sidebar-icon {
    @apply h-5 w-5 mr-3 text-neutral-400 my-auto;
}